/* Common styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

a {
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  text-decoration: underline;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/*
.section-header {
  cursor: pointer;
}
*/

.boxed-header {
  border: 2px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  background-color: #f9f9f9;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

}

.boxed-header h2 {
  margin: 0; 
}


.wrapper {
  display: flex;
  flex-grow: 1;
}

/* Tab Header */
.tab-header {
  display: flex;
  cursor: pointer;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid;
  transition: background-color 0.3s, border-color 0.3s;
}

.tab-header a {
  padding: 10px;
  margin-right: 10px;
  align-items: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.tab-header a:hover {
  transform: scale(1.1);
}

/* Tab Content */
.tab-content {
  padding: 20px;
  transition: background-color 0.3s, border-color 0.3s;
}

.main-content {
  flex: 1;
  margin-left: 200px;
  margin-top: 0px;
  padding: 0px;
  transition: margin-left 0.3s ease;
}

.side-menu.collapsed + .main-content {
  margin-left: 80px;
}
.content {
  flex: 1;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

/* Images */
.image-container {
  text-align: center;
  margin: 20px 0;
}

.image-subtitle {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}
