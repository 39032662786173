/* Contact.css */

.logo-container {
    display: flex;
    justify-content: space-around; 
    align-items: center; 
    padding: 20px;
  }
  
  .logo {
    width: 250px;
    height: 250px;
    transition: transform 0.2s; 
  }
  
  .logo:hover {
    transform: scale(1.05);
  }
  