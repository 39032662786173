/* Footer */
.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Footer Content */
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer p {
    margin: 0;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #007bff;
  }