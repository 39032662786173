.side-menu {
  width: 180px;
  position: fixed;
  top: 60px; 
  left: 0;
  height: calc(100% - 60px); 
  background-color: #333;
  color: #fff;
  padding: 10px 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1;
  transition: width 0.3s ease;
}

.side-menu.collapsed {
  width: 60px;
}

.side-menu h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #00FF00;
  text-align: center;
}

.side-menu.collapsed h2 {
  display: none;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-menu ul li {
  margin: 10px 0;
}

.side-menu ul li button {
  display: block;
  color: #fff;
  background: none;
  border: none;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s, color 0.3s;
}

.side-menu ul li button:hover {
  background-color: #444;
  color: #007bff;
}

.side-menu ul li button.active {
  font-weight: bold;
  background-color: #444;
  color: #007bff; /* Customize the color as needed */
}

.toggle-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  width: 100%;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #444;
}

.toggle-button .fa-icon {
  font-size: 1.5em;
}
